$(document).ready(function() {
    let wrapper = $('.webhooks');
    wrapper.on('click', '.remove-webhook-link', function(e) {
        e.preventDefault();
        $(this).closest('.webhooks-item')
            .fadeOut()
            .remove();
    });
    wrapper.on('click', '.add-webhook-link', function(e) {
        e.preventDefault();
        let prototype = wrapper.data('prototype');
        let index = wrapper.data('index');
        let newForm = prototype.replace(/__name__/g, index);

        wrapper.data('index', index + 1);
        $(this).before(newForm);
    });
});