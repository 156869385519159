const tableSortJs = require('table-sort-js/table-sort.js');

$("#get-rncs-api-data-btn").on('click', function (event) {
    const btn = $(this);
    const siren = btn.data('siren');
    $(this).addClass('btn-loading');
    $.get(`/admin/data/rncs-api?siren=${siren}`)
        .done(function (response) {
            $('#rncs-api-alert').addClass('d-none');
            btn.hide();

            $.each(response, function (dataName, data) {
                let name = $('<h3 class="text-capitalize">').text(dataName);

                let container = $('<div class="table-responsive mb-3">').append(createTable(data));

                $("#rncs-api-data-container").append(name).append(container);
            })

            $("#rncs-api-data-container").removeClass('d-none');

            tableSortJs(true, document.getElementById("rncs-api-data-container"));

        })
        .fail(function (response) {
            btn.removeClass('btn-loading');
            let alert = $('#rncs-api-alert');
            const closeCross = $('<a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close">');
            alert.removeClass('d-none');

            if (response.status === 500) {
                alert.text('Unable to fetch data, RNCS-API is probably down');
            } else {
                alert.text('No data for this siren');
            }

            alert.append(closeCross);

        })
});

function createTable(data) {
    const fieldNames = getFieldNames(data);

    let table = $('<table class="table table-bordered table-nowrap table-sort table-arrows scroll-x">');

    let header = $('<thead>').append(
        fieldNames.map((field) => {
            let name = field.replaceAll(/[A-Z]/gm, '_$&');
            return $(`<th class="text-lowercase px-2 border-1" style="font-size: 0.875rem;white-space: nowrap">`).text(name)
        })
    );

    let body = $('<tbody>').append(
        data.map((obj) => {
            return $(`<tr>`).append(
                fieldNames.map((field) => {
                    if (obj[field]) {
                        if (field === 'idFichier') {
                            return $(`<td>`).append($('<a target="_blank" href="/admin/data/rncs-api/files/' + obj[field] + '"></a>').text(obj[field]));
                        }

                        let value = obj[field]

                        if (typeof value === 'object') {
                            value = Object.values(obj[field]).join(', ');
                        }

                        return $(`<td>`).text(value);
                    }

                    return $(`<td>`).append($('<span class="badge bg-azure-lt">null</span>'));
                })
            );
        })
    );

    table.append(header, body);

    return table;
}

function getFieldNames(datas) {

    let fields = [].concat(...datas.map((array) => {
        return Object.keys(array);
    }));

    return [...new Set(fields)];
}