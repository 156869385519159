$(document).ready(function() {

    $.initialize('.dropdown.dropdown-filter', function () {
        var $element = $(this);
        var $filterContainer = $('#'+$element.data('dropdown-filter-container'));

        $element.find('a.dropdown-item').on('click', function(e) {
            e.preventDefault();

            $element.find('a.dropdown-item.active').removeClass('active');
            $(this).addClass('active');
            $element.find('a.dropdown-toggle').text($(this).text());

            var target = $(this).data('dropdown-filter-target');
            if (target) {
                $filterContainer.find('[data-dropdown-filterable]').hide();
                $filterContainer.find('[data-dropdown-filterable="'+target+'"]').show();
            } else {
                $filterContainer.find('[data-dropdown-filterable]').show();
            }
        });

        $element.find('a.dropdown-item.active').trigger('click');
    });
})