let siren = (new URL(document.location)).searchParams.get('siren');

$('#rnebeneficiaireeffectif-refresh').click(function () {
    $.ajax({
        url:  `/admin/data/company/refresh/rbe-people?siren=${siren}`,
        type: 'GET',
        success: (res) => updateEntityTable(res.RneBeneficiaireEffectif, $('#rnebeneficiaireeffectif'))
    })
})

$('#rneacte-refresh').click(function () {
    $.ajax({
        url:  `/admin/data/company/refresh/rbe-documents?siren=${siren}`,
        type: 'GET',
        success: (res) => updateEntityTable(res.RneActe, $('#rneacte'))
    })
})

function updateEntityTable(entityList, $table) {
    let tbody = $table.find('tbody')[0];

    $table.find("tbody tr").remove();

    for (const entity of entityList) {
        let newRow = tbody.insertRow();

        for (const key in entity) {
            let newCell = newRow.insertCell();
            newCell.className = 'small';
            if (entity[key] === null) {
                newCell = newCell.appendChild(document.createElement('span'));
                newCell.setAttribute('class', 'badge bg-azure-lt');
            }
            newCell.appendChild(document.createTextNode(entity[key]));
        }
    }
}
