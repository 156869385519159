import {EditorView, keymap, highlightSpecialChars, drawSelection, highlightActiveLine, dropCursor} from "@codemirror/view"
import {Extension, EditorState, EditorStateConfig} from "@codemirror/state"
import {history, historyKeymap} from "@codemirror/history"
import {foldGutter, foldKeymap} from "@codemirror/fold"
import {indentOnInput} from "@codemirror/language"
import {lineNumbers, highlightActiveLineGutter} from "@codemirror/gutter"
import {defaultKeymap, indentWithTab} from "@codemirror/commands"
import {bracketMatching} from "@codemirror/matchbrackets"
import {closeBrackets, closeBracketsKeymap} from "@codemirror/closebrackets"
import {searchKeymap, highlightSelectionMatches} from "@codemirror/search"
import {autocompletion, completionKeymap} from "@codemirror/autocomplete"
import {commentKeymap} from "@codemirror/comment"
import {rectangularSelection, crosshairCursor} from "@codemirror/rectangular-selection"
import {defaultHighlightStyle} from "@codemirror/highlight"
import { lintGutter, linter, openLintPanel, lintKeymap} from "@codemirror/lint"
import {json, jsonParseLinter as jsonLint} from "@codemirror/lang-json";
import {html} from "@codemirror/lang-html";
import {markdown} from "@codemirror/lang-markdown";

let basicSetup = [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    history(),
    foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    defaultHighlightStyle.fallback,
    bracketMatching(),
    closeBrackets(),
    autocompletion(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    lintGutter(),
    keymap.of([
        ...closeBracketsKeymap,
        ...defaultKeymap,
        ...searchKeymap,
        ...historyKeymap,
        ...foldKeymap,
        ...commentKeymap,
        ...completionKeymap,
        ...lintKeymap,
    ])
];

const languageExtensions = {
    json: [json(), linter(jsonLint())],
    html: [html()],
    markdown: [markdown()],
    //add extensions from other languages here
    //the norm for language names is kebab-case, to be consistent with html norms
};


const $ = require('jquery');
const jQuery = $;

require('jquery.initialize');
$.initialize('.code_editor-wrapper[data-toggle=code_editor]', function() {
    let $this = $(this);

    let valueFuture = Promise.resolve(null);
    let contentStorageTextarea = null;

    let url = $this.attr('href') || $this.data('target');
    let lineWrapping = $this.data('code-line-wrap');

    if (url) {
        //For now, directly pass the first non error result without worrying about failures, or No Content Available Yet style of results
        valueFuture = $.get(url).then(res => res);
    } else if ($this.data('content')) {
        valueFuture = Promise.resolve($this.data('content'));
    } else if ($this.children('textarea.code_editor-content').length) {
        contentStorageTextarea = $this.children('textarea.code_editor-content')[0];

        let $textareaContent = $(contentStorageTextarea);
        url = $textareaContent.attr('href') || $textareaContent.data('target');

        if (url) {
            valueFuture = $.get(url).then(res => res);
        } else if ($textareaContent.data('content')) {
            valueFuture = Promise.resolve($textareaContent.data('content'));
        } else {
            valueFuture = Promise.resolve(contentStorageTextarea.value);
        }
    }

    valueFuture.then(value => {
        let extensions = [];
        let data = $this.data();

        extensions = [...extensions, ...(languageExtensions[data.language] || [])];

        if (data.readonly) {
            extensions.push(
                //EditorView.editable.of(false),
                EditorState.readOnly.of(true)
            );
        }
        if (['dark', 'light'].includes(data.theme)) {
            extensions.push(EditorView.darkTheme.of('dark' === data.theme));
        }
        if (data['lineWrap']) {
            extensions.push(EditorView.lineWrapping);
        }

        let state = EditorState.create({
            extensions: [...basicSetup, ...extensions],
            doc: value,
        });

        if (contentStorageTextarea) {
            let editor = new EditorView({
                state: state,
            });
            $(contentStorageTextarea)
                .css({display: 'none'})
                .before(editor.dom);
            $this.parents('form').first()
                .on('submit', () => contentStorageTextarea.value = editor.state.doc.toString());
            $this.data('Codemirror', editor);
        } else {
            $this.data('Codemirror', new EditorView({
                state: state, parent: $this.get(0)
            }));
        }
    }, ()=>null);
});
