$(document).ready(function() {
    let recaptcha = $('.g-recaptcha-response');

    recaptcha.map((i, e) => $(e).val($(e).val().trim()));
    recaptcha.map((i, e) => e.required = true);
    recaptcha.map((i, e) => e.minLength = 1);

    recaptcha.map((i, e) => e.oninvalid = function(e) {
        $('#invalid-captcha').removeClass('d-none').addClass('d-block');
    });
});
