const moment = require("moment");

$(() => {

    const removeAttribut = (element, attributName) => {
        const attribut = element.attr(attributName);

        if (attribut) {
            element.removeAttr(attributName)
        }
    }

    const updateMoment = (element) => {
        const date = element.data("date");
        element.text(moment(date, "YYYY-MM-DD hh:mm:ss").fromNow());
    }

    const formatNumber = (number) => {
        return number < 10 ? "0" + number : number;
    }

    const getDateFormatFr = (date) => {
        const seconds = formatNumber(date.getSeconds());
        const minutes = formatNumber(date.getMinutes());
        const hours = formatNumber(date.getHours());
        const day = formatNumber(date.getDate());
        const month = formatNumber(date.getMonth() + 1);
        const year = date.getFullYear();

        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }

    $(".date_diff_provider").each((index, element) => {
        updateMoment($(element));
    });

    $(".refresh-health-check.ajax-load")
        .off("pre-fetch.ajaxLoad.loading pre-fetch-vetoed.ajaxLoad post-fetch.ajaxLoad.loading")
        .on("pre-fetch.ajaxLoad.loading", (_, $this) => {
            $this.addClass("spin")
                .attr("disabled", true)
                .tooltip("hide")
        })
        .on("pre-fetch-vetoed.ajaxLoad.loading", (_, $this) => $this.addClass("spin"))
        .on("fetch-success.ajaxLoad.loading", function (_, response) {
            $(this).removeClass("spin").attr("disabled", false);

            const id = $(this).data("provider-id").split("_").slice(-1);
            const card = $("div[data-provider-id='card_provider_" + id + "']");
            const spansDate = card.find("span[data-date]");
            const now = getDateFormatFr(new Date());

            spansDate.each((index, element) => {
                $(element).data("date", now);
                $(element).siblings("span").text(now);

                updateMoment($(element));
            })

            $(`#modal_provider_${id} .error_message`).text(response.message);
            $(`#modal_client_datatype_${id} .error_message`).text(response.message);

            if (response.status === "OK") {
                card.find(".ok-check").show();
                card.find(".ko-check").hide();

                card.find(".card-title").removeAttr("data-bs-toggle");
                card.find(".card-title").removeAttr("data-bs-target");
                card.find(".card-title").removeClass("cursor-pointer");

                card.find(".card-content").removeAttr("data-bs-toggle");
                card.find(".card-content").removeAttr("data-bs-target");
                card.find(".card-content").removeClass("cursor-pointer");

            } else {
                card.find(".ko-check").show();
                card.find(".ok-check").hide();

                card.find(".card-title").attr("data-bs-toggle", "modal");
                card.find(".card-title").attr("data-bs-target", "#modal_provider_" + id);
                card.find(".card-title").addClass("cursor-pointer");

                card.find(".card-content").attr("data-bs-toggle", "modal");
                card.find(".card-content").attr("data-bs-target", "#modal_provider_" + id);
                card.find(".card-content").addClass("cursor-pointer");
            }
        })
});