(function () {
    /**
     * Show notification with $.notify (bootstrap-notify)
     *
     * @param notif Must have notif.type & notif.message
     */
    function notify(notif) {
        const icons = {
            'primary': 'fa fa-lightbulb',
            'secondary': 'fa fa-bell',
            'success': 'ti ti-circle-check text-green',
            'warning': 'fa fa-exclamation-triangle',
            'danger': 'ti ti-alert-triangle text-red',
            'info': 'fa fa-info',
        };

        if (!notif) {
            return;
        }

        $.notify({
            icon: icons[notif.type] + ' h2 m-0',
            message: notif.message,
        }, {
            type: {error: 'danger'}[notif.type] || notif.type,
            delay: 10000,
            allow_dismiss: true,
            placement: {
                from: "bottom",
                align: "center"
            },
            z_index: 1500,
            template: `<div data-notify="container" class="col-xs-11 col-sm-4 alert alert-{0}" role="alert">
    <button type="button" aria-hidden="true" class="close btn-close" data-notify="dismiss"></button>
    <div class="d-flex gap-1 align-content-center">
    <span data-notify="icon"></span>
    <span data-notify="message">{2}</span>
    </div>
</div>`
        });
    }

    $(document).on('form-valid', '.modal form.ajax-submit.ajax-submit-remove-on-success', function () {
        let $modal = $(this).parents('.modal');
        $modal
            .one('hidden.bs.modal', () => $modal.modal('dispose').remove())
            .modal('hide');
    });

    $.initialize('form.ajax-submit', function () {
        $(this).on('submit', function (ev) {
            ev.preventDefault();

            let $form = $(this);
            let $submitter = $(ev.originalEvent.submitter);

            function onComplete() {
                $form.find('[type="submit"]').removeAttr('disabled');
                $submitter.children('i').remove();
            }

            $form.find('[type="submit"]').attr('disabled', true);
            $form.find('.to-disable').addClass('disabled');
            $submitter.prepend('<i class="ti ti-loader icon spin">');

            $form.ajaxSubmit({
                dataType: 'json',
                data: (data) => ({...($submitter ? {[$submitter.attr('name')]: true, ...data,} : {})}),
                // Called on unexpected error (500, 404, 403, json parse error, ...)
                error: function (xhr) {
                    onComplete();
                    $form.trigger('ajax-error', xhr);

                    notify({message: "Une erreur s'est produite. Veuillez réessayer ultérieurement", type: 'danger'});
                },
                success: function (response) {
                    if (response.redirect) {
                        document.location.href = response.redirect;
                        return;
                    }

                    onComplete();
                    $form.trigger('ajax-success', response);

                    if (response.form) {
                        let newForm = $(response.form);
                        $form.replaceWith(newForm);
                        $form = newForm;
                    }

                    if (response.valid === false) {
                        $form.trigger('form-error', response);
                    } else if (response.valid === true) {
                        $form.trigger('form-valid', response);
                    }

                    if (response.modal) {
                        $(response.modal).appendTo('body').modal('show').on('hidden.bs.modal', function () {
                            $(this).modal('dispose').remove();
                        });
                    }

                    if (response.notifications) {
                        $('.notifications-scroll-fix').children().replaceWith(response.notifications);
                    }

                    response.notification && notify(response.notification);
                }
            });
        });
    });

    $.initialize('[href].ajax-load, [data-url].ajax-load', function () {
        let $this = $(this);

        $this
            .on('pre-fetch.ajaxLoad.loading', (_, $this) => $this
                .prepend('<i class="ti ti-loader icon spin ajax-loading-icon">')
            )
            .on('pre-fetch-vetoed.ajaxLoad.loading', (_, $this) => $this
                .prepend('<i class="ti ti-loader icon spin ajax-loading-icon">')
            )
            .on('post-fetch.ajaxLoad.loading', (_, $this) => $this
                .find('.ajax-loading-icon').remove()
            )
            .click(function (ev) {
                ev.preventDefault();

                let data = $this.data('extra');
                data = typeof data !== 'undefined' ? data : {};
                let veto = {};

                $this.trigger('pre-fetch.ajaxLoad', [$this, data, veto]);

                if (veto.veto) {
                    //Request was vetoed, return
                    $this.trigger('pre-fetch-vetoed.ajaxLoad.loading', [$this, data]);
                    return;
                }

                $this.addClass('loading');

                let xhr = $.ajax($this.attr('href') || $this.data('url'), {
                    data: data,
                    complete: function () {
                        $this.removeClass('loading');
                        $this.trigger('post-fetch.ajaxLoad', [$this, data]);
                    },
                    success: function (response, textStatus, xhr) {
                        if (response.redirect) {
                            document.location.href = response.redirect;
                            return;
                        }

                        $this.trigger('fetch-success.ajaxLoad', [response, textStatus, xhr]);

                        if (response.modal) {
                            //$('body').append(response.modal).modal('show'); // doesnt work
                            $(response.modal).appendTo('body').modal('show').on('hidden.bs.modal', function () {
                                $(this).modal('dispose').remove();
                            });
                        }

                        if (typeof response.valid === 'boolean') {
                            const ev = response.valid ? 'valid' : 'invalid';
                            $this.trigger(`${ev}.ajaxLoad`, response);
                        }

                        if (response.notifications) {
                            $('.notifications-scroll-fix').children().replaceWith(response.notifications);
                        }

                        notify(response.notification);
                    },
                    error: function (xhr) {
                        $this.trigger('fetch-error.ajaxLoad', xhr);
                        $.notify("Une erreur s'est produite. Veuillez réessayer ultérieurement", {type: 'danger'});
                    }
                });
            });
    });
})();
