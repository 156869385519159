$(document).ready(function() {
    $('.filter')
        .on('switch.filter', function (ev, visible) {
            if (ev.target !== this) return;

            var $this = $(this);

            if (typeof visible === 'undefined') {
                $this.toggleClass('filter-on');
            } else if (visible !== null) {
                $this.toggleClass('filter-on', visible);
            }

            if ($this.hasClass('filter-on')) {
                $this.trigger('shown.filter');
            } else {
                $this.trigger('hidden.filter');
            }
        })
        .on('hidden.filter', function (ev) {
            if (this !== ev.target) return;

            $(this).find('.filter-val').val(null);
        })
    ;

    $('.filter .filter-clear').click(function () {
        $(this).closest('.filter').trigger('switch.filter', [false]);
    });

    $('.filter-switch[data-target]')
        .each(function (i, e) {
            const switchElement = $(e);
            const target = $(switchElement.data('target'));

            switchElement.click(function (ev) {
                const targetEvent = $(ev.target);
                if(targetEvent.hasClass('filter-switch')){
                    const input = targetEvent.children('input.form-check-input');
                    input.prop('checked',!input.is(':checked'));
                }

                target.trigger('switch.filter');
            })
        })
    ;

    $('.filter-box')
        .on('shown.filter hidden.filter', '.filter', function () {
            var $this = $(this);
            var $box = $this.is('.filter-box:not(.filter)') ? $this : $this.closest('.filter-box');
            $box.toggle($box.find('.filter.filter.filter-on').length > 0);
        })
        .each(function (i, e) {
            var $this = $(this);
            var $box = $this.is('.filter-box:not(.filter)') ? $this : $this.closest('.filter-box');
            $box.toggle($box.find('.filter.filter.filter-on').length > 0);
        })
    ;

    $('.filter.filter-range select.filter-op')
        .change(function (ev) {
            var $this = $(this);
            var $opt = $this.find('option:selected').first();
            var requiredFieldsCount = parseInt($opt.attr('data-required-fields'));

            $this.closest('.filter.filter-range').find('.filter-val').each(function (idx, el) {
                var $field = $(el);
                var $group = $field.closest('.input-group');

                if (requiredFieldsCount <= $field.data('order'))
                    $group.hide();
                else
                    $group.show();
            })
        })
        .change()
    ;

//Launch filter state events
    $('.filter').trigger('switch.filter', [null]);

    $('.icheckbox_minimal-blue .serial-child.serial-child-icheck').each(function (i, e) {
        $(e).attr('data-order', i);
    });

    $('.serial-child.serial-child-icheck')
        .on('ifClicked', function (ev) {
            if (ev.target !== this) return;

            var $this = $(ev.target);
            var $parent = $this.closest('.serial-parent');
            var lastClicked = $parent.data('serial-last-clicked');

            if (lastClicked && $(window).data('keys-down').shift) {
                var lastIndex = $(lastClicked).data('order');
                var thisIndex = $this.data('order');
                var indexDir = Math.sign(thisIndex - lastIndex);

                if (lastIndex !== thisIndex) {
                    for (var i = lastIndex + indexDir; i !== (thisIndex + indexDir); i += indexDir) {
                        $parent.find(".serial-child.serial-child-icheck[data-order=" + i + "]").each(function (i, e) {
                            $(e).iCheck('toggle');
                        })
                    }
                }
            }

            $parent.data('serial-last-clicked', $this.get(0));
        })
    ;

    $('#per_page').change(function () {
        let query = {};
        let search = location.search.substring(location.search[0] === '?');

        if (search) {
            query = JSON.parse(
                '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                (key, value) => key === "" ? value : decodeURIComponent(value)
            );
        }

        let serialize = function (obj, prefix) {
            return Object.keys(obj).map(k => (
                (obj[k] !== null && typeof obj[k] === "object")
                    ? serialize(obj[k], k)
                    : encodeURIComponent(prefix ? `${prefix}[${k}]` : k) + "=" + encodeURIComponent(obj[k])
            )).join('&');
        };

        query.per_page = $(this).val();
        document.location.search = "?" + serialize(query);
    });

    $('form[name="filter"]').on('submit', function () {
        const unserialize = function (dt) {
            //In case of select multiple, gather all values into one array
            let indexing = {};
            dt.forEach((kv, idx) => indexing[kv.name] = [...(indexing[kv.name] || []), idx]);
            Object.keys(indexing)
                .filter(k => indexing[k].length > 1) //We're only doing this for value names with more than 1 occurence
                .forEach(name => {
                    let value = indexing[name].map(i => dt[i].value);

                    //remove existing instances of array values
                    dt = dt.filter((_, i) => !indexing[name].includes(i));
                    //push entire list of values
                    dt.push({name, value});
                });

            let extend = (acc, keys, val) => $.extend(true, {}, acc,
                (keys.length <= 0 ? {} : {
                    [keys[0]]: keys.length > 1 ? extend({}, keys.slice(1), val) : val
                })
            );

            return $.extend(
                true, {},
                ...dt.map(kv => extend({}, kv.name.replace(/\[(\w+)]/gi, '.$1').split('.'), kv.value))
            );
        };
        const isNotEmpty = kv => {
            let val = '';

            if (kv.hasOwnProperty('val') || kv.hasOwnProperty('val[]')) {
                val = kv.val;
            } else if (kv.hasOwnProperty('lower_val')) {
                val = [kv.lower_val, kv.upper_val].filter(x => (x !== ''));
            }

            if (val instanceof Array) return val.length > 0;
            return val !== '';
        };
        const reserialize = function (dt) {
            let extend = (o, p = '') => [].concat(...Object.keys(o).map(
                k => {
                    let name = ((p && `${p}.`) + k).replace(/\.(\w+)/gi, '[$1]');
                    if (o[k] instanceof Array)
                        return o[k].map(value => ({name, value}));
                    if (typeof o[k] === 'object')
                        return extend(o[k], name);
                    return [{name, value: o[k]}];
                }
            ));

            return extend(dt);
        };

        let orig = $(this).serializeArray()
        let purged = unserialize(orig);
        purged.filter = $.extend(true, {},
            ...Object.keys(purged.filter)
                .filter(k => isNotEmpty(purged.filter[k]))
                .map(k => ({[k]: purged.filter[k]}))
        );
        purged = reserialize(purged, 'filter');

        orig.forEach(kv => {
            if (!purged.some(p => p.name === kv.name)) {
                $(`form[name="filter"] [name="${kv.name}"]`)
                    .prop('disabled', true)
                    .addClass('disabled')
                    .attr('disabled', 'disabled');
            }
        });
    });

    $(".batch-check-page").on('change', function (ev) {
        var $this = $(this);
        $this
            .closest('.list-all')
            .find('table')
            .find('tbody input[type="checkbox"][name="idx[]"]')
            .each(function (i, e) {
                $(e).prop('checked', $this.prop('checked'));
            })
        ;
    });

    $('#batch-form').submit(function (ev){
        ev.preventDefault();
        let form = $(this);
        let url = form.attr('data-url');
        $.post({
            url: url,
            data: form.serialize(),
            success: function (response) {
                if (response.type === 'modal') {
                    $('#modal-container').html(response.data);
                    $('#modal-confirmation').modal('show');
                } else{
                    $('#flash-messages').html(response.data);
                }
            }});
    });
});
