const setAvailable = (element) => {
    const isAvailable = element.is(':checked');
    $.ajax({
        method: 'POST',
        url: element.attr('data-url'),
        data: {
            available: isAvailable
        },
        success: function (response) {

        },
        error: function (response) {
            element.val(0);
        },
    })
};

const updateEnabledColum = (data) => $('#is_enabled_'+data.providerId)
    .html((data.enabled) ? '<span class="badge bg-info">true</span>' : '<span class="badge">false</span>');

const updateAutoConnectColum = (data) => $('#is_auto_connect_'+data.providerId)
    .html((data.autoConnect) ? '<span class="badge bg-info">true</span>' : '<span class="badge">false</span>');

$(() => {
    $('.available-provider').click((event) => {
        setAvailable($(event.target));
    });

    $(document).on('ajax-success',  function(event,response) {
        if (response.data) {
            $('#set_available_'+response.data.providerId).prop('checked', response.data.available);
            updateEnabledColum(response.data);
            updateAutoConnectColum(response.data);
        }
    });
});
