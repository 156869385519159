$('i[name="showPassword"]').each(function() {
    var show = false;
    $(this).click(function() {
        if (show === false) {
            show = true;
            $(this).closest('.input-group').find('input.form-control').attr('type', 'text');
            $(this).attr('class', 'ti ti-eye-off');
        }
        else {
            show = false;
            $(this).closest('.input-group').find('input.form-control').attr('type', 'password');
            $(this).attr('class', 'ti ti-eye');
        }
    })
});