$(document).ready(function() {
    $.initialize('.copyable', function () {
        $(this).on('click', function(e) {
            e.preventDefault();

            var $storage = $('<textarea></textarea>').val($(this).parent().text()).appendTo($(this).parent());
            $storage.select();
            document.execCommand('copy');
            $storage.remove();

            var $this = $(this);
            $this.removeClass('copied');
            setTimeout(function() { $this.addClass('copied') });
        });
    });
})