$('#fetch-banks-btn').on('click', function () {
    const btn = $(this);
    const siren = btn.data('siren');
    $(this).addClass('btn-loading');
    $('#banks-alert-info').addClass('d-none');
    $('#banks-alert-danger').addClass('d-none');

    $.get(`/admin/data/banks?siren=${siren}`)
        .done(function (response) {
            btn.hide();
            if (response.length) {
                let tableBody = '';
                $.each(response, function (i, item) {
                    tableBody += `<tr><th>${i+1}</th><td>${item.bank.name}</td><td><span class="badge bg-azure-lt">${item.bank.siren}</span></td><td>${item.bank.source}</td></tr>`;
                })

                $('#banks-table tbody').html(tableBody);
                $('#banks-table').removeClass('d-none');
            } else {
                $('#banks-alert-info').removeClass('d-none');
            }
        })
        .fail(function () {
            btn.removeClass('btn-loading');
            $('#banks-alert-danger').removeClass('d-none');
        })
});