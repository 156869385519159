
var SEARCH_URL;

$(document).ready(function() {
    SEARCH_URL = $('#company-search').attr('action');
    $('#company-search-spinner').hide();
    search($('#company-search-query').val());
});

$('#company-search').on('submit', function(e) {
    e.preventDefault();
    search($('#company-search-query').val(), true);
});


$('#company-search-query').on('keyup', function(e) {
    search($(this).val());
});

var searchTimer;
var searchRequest;
var lastSearch;

function search(query, force = false)
{
    if (!query) {
        searchRequest?.abort();

        $('#company-search-result').empty();

        return;
    }

    if (query === lastSearch && !force) {
        return;
    }

    lastSearch = query;
    clearTimeout(searchTimer);

    searchTimer = setTimeout(function() {
        searchRequest?.abort();

        $('#company-search-result').empty();
        $('#company-search-spinner').show();

        searchRequest = $.ajax({
            url: SEARCH_URL,
            data: {q: query},
        }).always(function(response) {
            $('#company-search-spinner').hide();
            searchRequest = null;
        }).done(function(response) {
            $('#company-search-result').html(response);
        });
    }, force ? 0 : 250);
}