$(() => {
    $('img.tm-image-resource-pending').each((i, e) => {
        const imgObserver = new IntersectionObserver(entries => entries.forEach(({target}) => {
                imgObserver.unobserve(target);

                $(target).filter('.tm-image-resource-pending')
                    .each((_, t) => {
                        const $t = $(t);

                        $t.removeClass('tm-image-resource-pending')
                            .one('error', () => $t.replaceWith(
                                $('<span>').html($t.attr('alt')).css({
                                    'font-family': 'Courier New',
                                    'font-size': '170%',
                                })
                            ))
                            .attr('src', $t.data('url'))
                        ;
                    });
            }),
            {rootMargin: '10%', threshold: .001}
        );

        imgObserver.observe(e);
    });
})