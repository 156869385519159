$(document).ready(function() {
    let btn = $('#lang-switcher');

    window.addEventListener('click', () => {
        if  (btn.attr('aria-expanded') === "true") {
            btn.css('boxShadow', "inset 0px 0px 5px rgba(0, 0, 0, 0.25)");
        }
        else {
            btn.css('boxShadow', "none");
        }
    })
});
