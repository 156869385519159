
import Tagify from "@yaireo/tagify";

$(document).ready(function() {
    function dasbordChart(element){
        let series = element.getAttribute('data-series');
        let labels = element.getAttribute('data-labels');
        console.log(labels);
        let chartD = new ApexCharts(element, {
            chart: {
                type: element.getAttribute('data-type'),
                fontFamily: 'inherit',
                height: 40.0,
                width: '100%',
                sparkline: {
                    enabled: true
                },
                animations: {
                    enabled: false
                },
                events: {
                    mounted: function (charContext, config) {
                        setTimeout(() => {
                            this.width = '100%'
                        }, 1)
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: 1,
            },
            stroke: {
                width: [2, 1],
                dashArray: [0, 3],
                lineCap: "round",
                curve: "smooth",
            },
            series: [{
                name: "Nombre",
                data: JSON.parse(series).map((i) => Number(i)),
            }],
            grid: {
                strokeDashArray: 4,
            },
            xaxis: {
                labels: {
                    padding: 0,
                },
                tooltip: {
                    enabled: false
                },
                axisBorder: {
                    show: false,
                },
                type: 'category',
            },
            yaxis: {
                labels: {
                    padding: 4
                },
            },
            labels: JSON.parse(labels).map((i) => i+"h"),
            colors: ["#1ACAA1", "rgba(191,243,230,0.91)"],
            legend: {
                show: false,
            },
        });

        chartD.render();
    }

    $.initialize('#chart-WebhookLog-bg', function() {
        dasbordChart(document.getElementById('chart-WebhookLog-bg'));
    });
    $.initialize('#chart-Log-bg', function() {
        dasbordChart(document.getElementById('chart-Log-bg'));
    });

    const tagInputs = document.getElementsByClassName("tag-input");

    for (const tagInput of tagInputs) {
        new Tagify(tagInput, {});
    }
});