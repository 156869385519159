$(document).ready(function() {
    $(".btn-datainfogreffe-confirm").on('click', function () {
        const btn = $(this);
        let url = btn.data('url');
        const type = btn.data('type');

        if (type === 'annual_accounts') {
            let year = $('#annual_accounts_select select').val();

            if (year === 'custom_year') {
                year = $('#customer_year_input').val();
            }

            url += `&year=${year}`;
        }

        $(`#get-datainfogreffe-api-${type}-data-btn`).addClass('btn-loading');

        $.get(url)
            .done(function (response) {
                $(`#datainfogreffe-api-${type}-alert`).addClass('d-none');
                $(`#get-datainfogreffe-api-${type}-data-btn`).hide();

                if (type === 'annual_accounts') {
                    $('#annual_accounts_select').addClass('d-none');
                }

                let container = $('<div class="table-responsive mb-3">').append(createTable(response['Data']));

                $(`#datainfogreffe-api-${type}-container`).append(container).removeClass('d-none');

            })
            .fail(function (response) {
                $(`#get-datainfogreffe-api-${type}-data-btn`).removeClass('btn-loading');
                let alert = $(`#datainfogreffe-api-${type}-alert`);
                const closeCross = $('<a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close">');
                alert.removeClass('d-none');

                if (response.status === 500) {
                    alert.text('Unable to fetch data, DATAINFOGREFFE-API is probably down');
                } else if (response.status === 404) {
                    alert.text('No data found for this siren');
                } else if (response.status === 401) {
                    alert.text('Invalid token');
                } else {
                    alert.text('An error occurred.');
                }

                alert.append(closeCross);
            })
    });

    $('#annual_accounts_select select').on('change', function () {
        let input = $('#customer_year_input');
        if ($(this).val() === 'custom_year') {
            input.removeClass('d-none');
        } else {
            input.addClass('d-none');
            input.val(null);
        }
    })

    function createTable(data) {
        let table = $('<table class="table table-bordered table-nowrap scroll-x scroll-y">');
        let body = $('<tbody>');

        body = createTableRow(data, body);
        table.append(body);

        return table;
    }

    function createTableRow(data, body) {
        $.each(data, function (key, value) {
            if (typeof(value) === 'object' && value !== null) {
                createTableRow(value, body);
            } else {
                let tr = $('<tr>');
                if (value === null) {
                    tr.append(`<td>${key}</td><td><span class="badge bg-azure-lt">${value}</span></td>`);
                } else {
                    tr.append(`<td>${key}</td><td>${value}</td>`);
                }
                body.append(tr);
            }
        });

        return body;
    }
});
