import hljs from "highlight.js";

const $ = require('jquery');
global.$ = global.jQuery = $;

import './styles/app.scss';
require('jquery.initialize');
require('@tabler/core');
require('bootstrap-notify');
require('jquery-form');
require('flatpickr');
require('table-sort-js/table-sort.js');
require('./components/filter');
require('highlight.js/styles/stackoverflow-dark.css');
require('./components/recaptcha')

const prettyJson = code => JSON.stringify(JSON.parse(code), null, 2);
hljs.addPlugin({
    'before:highlightElement': ({el, language}) => {
        if (!el.hasAttribute('data-pretty')) return;
        if ('json' !== language) return;

        try {
            el.innerHTML = JSON.stringify(JSON.parse(el.innerHTML), null, 2);
        } catch (e) {}
    }
});

hljs.initHighlightingOnLoad();

$('[datepicker=""]').flatpickr({
    dateFormat: "d-m-Y",
});

global.ApexCharts = require('apexcharts');
require('./components/dashboard');
require('./components/health-check');
require('./components/ajax-form');
require('./components/client_providers');
require('./components/client_data_type');
require('./components/public-data-banks');
require('./components/rncs-api-data');
require('./components/datainfogreffe-api-data');
require('./components/insee-api-data');
require('./components/trademark');
require('./components/fields/code_editor');
require('./components/client_webhooks');
require('./components/health-check-form');
require('./components/show-password');
require('./components/infogreffe_sandbox-modal');
require('./components/admin_refresh_company')
require('./components/dropdown-flags');
require('./components/company');
require('./components/dropdown-filter');
require('./components/copyable');
