const setEnable = (element) => {
    const isEnable = element.is(':checked');
    $.ajax({
        method: 'POST',
        url: element.attr('data-url'),
        data: {
            enable: isEnable
        },
        error: function (response) {
            element.prop('checked', !isEnable);
        },
    })
}

const updateFrequencyColumn = (data) => $('#get_frequency_'+data.clientDataTypeId)
    .html((data.frequency === 'NONE') ? '<span class="badge">NONE</span>' : `<span class="badge bg-info">${data.frequency}</span>`);

const updateScheduleColumn = (data) => $('#get_schedule_'+data.clientDataTypeId)
    .html((data.schedule === null) ? '-' : data.schedule);

const updateInitialSyncColumn = (data) => $('#get_initial_sync_'+data.clientDataTypeId)
    .html((data.initialSync) ? '<span class="badge bg-info">true</span>' : '<span class="badge">false</span>');

const updateMonthHistoryColumn = (data) => $('#get_month_history_'+data.clientDataTypeId)
    .html((data.monthHistory === null) ? '-' : data.monthHistory);

const updateUpdatedAtColumn = (data) => $('#get_updated_at_'+data.clientDataTypeId)
    .html((data.updatedAt === null) ? '-' : data.updatedAt);

$(() => {
    $('.enable-client-data-type').click(
        event => setEnable($(event.target))
    );

    $(document).on('ajax-success',  function(event,response) {
        if (response.data) {
            $('#set_enable_'+response.data.clientDataTypeId).prop('checked', response.data.enable);
            updateFrequencyColumn(response.data);
            updateScheduleColumn(response.data);
            updateInitialSyncColumn(response.data);
            updateMonthHistoryColumn(response.data);
            updateUpdatedAtColumn(response.data);
        }
    });
});