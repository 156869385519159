$(document).ready(function() {
    let select = $('#health_check_form_handlerName');
    let textarea = $('.cm-content');
    select.change(function() {
        textarea.empty();
        $.ajax({
            url: "/admin/health-checks/credentials/"+select.val()
        }).done(function(data) {
            textarea.append("<div className='cm-line'>{</div>");
            for(i = 0; i < data.length; i++) {
                if(i === data.length-1) {
                    textarea.append("<div className='cm-line'>\"" + data[i] + "\": \"\"</div>");
                }
                else {
                    textarea.append("<div className='cm-line'>\"" + data[i] + "\": \"\",</div>");
                }
            }
            textarea.append("<div className='cm-line'>}</div>");
        });
    });
});